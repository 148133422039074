import { defineStore, storeToRefs } from "pinia";
import type { SubscriptionCallbackMutation, _StoreWithState, SubscriptionCallback } from "pinia";
import type {
    ControllersLoginOutput,
    ControllersRolesFindAllOutput,
    EntitiesAreaStores,
    EntitiesCompany,
    EntitiesStoresResponse,
    EntitiesUser,
    EntitiesV2HistoryPostData,
    EntitiesAreasResponse,
    EntitiesAreaStoresResponse,
    EntitiesCompaniesResponse,
    EntitiesRole,
    MybusinessbusinessinformationListCategoriesResponse,
    MybusinessbusinessinformationCategory,
    MybusinessbusinessinformationAttributeMetadata,
    MybusinessbusinessinformationListAttributeMetadataResponse,
} from "@/types/ls-api.d";
import { requiredAuth } from "@/helpers/request";
import type { SelectReport } from "@/helpers";
import localForage from "localforage";
import type { CustomSnackbarToast } from "@/helpers/request";

const notPersistKeys = ["storeRestored"];
export const useIndexedDb = defineStore("indexedDB", {
    state: () => ({
        storeRestored: false, // リロード時、storeにストレージからの注入が完了したか
        user: null as EntitiesUser,
        roleList: [] as EntitiesRole[],
        company: { name: "company name", poiGroupID: 0 } as EntitiesCompany,
        poiGroupId: 0,
        areas: {} as { [areaId: string]: string }, // areaId, areaNameの辞書
        areaStores: [] as EntitiesAreaStores[],
        stores: null as EntitiesStoresResponse,
        isDrawerOpened: true,
        isShowAllStores: false,
        isMobile: false,
        postRow: null as EntitiesV2HistoryPostData,
        selectReport: {
            selectReportType: "other",
            selectReportRange: null,
            selectStore: null,
        } as SelectReport,
        isShowAllCompanies: false,
        storesCurrentPage: 1,
        categories: [] as MybusinessbusinessinformationCategory[],
        attributeMetadata: {} as {
            [categoryId: string]: MybusinessbusinessinformationAttributeMetadata[];
        },
    }),
    getters: {
        userViewRange(): number {
            if (this.user == null) {
                return 0;
            }
            const role = this.roleList.filter((r) => r.roleLv == this.user.roleLv);
            if (role.length === 1) {
                return role[0].viewRange;
            }
            return 0;
        },
        isComUser(): boolean {
            if (this.user == null) {
                return false;
            }
            const role = this.roleList.filter((r) => r.roleLv == this.user.roleLv);
            if (role.length === 1) {
                switch (role[0].viewRange) {
                    case 1: // administrator(管理権限)
                        return true;
                    case 2: // companies(パワーユーザー)
                        return true;
                }
            }
            return false;
        },
        isInactiveHealthCheck(): boolean {
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            return currentRole?.functions?.includes("healthCheck:inactive-menu");
        },
        isInactiveSummary(): boolean {
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            return currentRole?.functions?.includes("metrics:inactive-menu");
        },
        isInactiveCompany(): boolean {
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            return currentRole?.functions?.includes("companies:inactive-menu");
        },
        isInactiveOwnCompany(): boolean {
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            return currentRole?.functions?.includes("companyconfig:inactive-menu");
        },
        isInactiveStore(): boolean {
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            return currentRole?.functions?.includes("stores:inactive-menu");
        },
        isInactiveGroup(): boolean {
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            return currentRole?.functions?.includes("groups:inactive-menu");
        },
        isInactiveUser(): boolean {
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            return currentRole?.functions?.includes("user:inactive-menu");
        },
        isInactiveProfile(): boolean {
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            return currentRole?.functions?.includes("myself:inactive-menu");
        },
        isInactiveCustomRoles(): boolean {
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            return currentRole?.functions?.includes("roles:inactive-menu");
        },
        isInactiveArea(): boolean {
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            return currentRole?.functions?.includes("groups:inactive-menu");
        },
        isInactiveStoreArea(): boolean {
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            return currentRole?.functions?.includes("stores:inactive-menu");
        },
        isInactiveBeta(): boolean {
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            return currentRole?.functions?.includes("beta:inactive-menu");
        },
        isInactiveReview(): boolean {
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            return currentRole?.functions?.includes("reviews:inactive-menu");
        },
        isInactivePost(): boolean {
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            return currentRole?.functions?.includes("post:inactive-menu");
        },
        isInactiveReport(): boolean {
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            return currentRole?.functions?.includes("report:inactive-menu");
        },
        isInactiveStoreLocation(): boolean {
            // 店舗情報・基本情報メニュー
            if (this.user == null) {
                return false;
            }
            // すべて満たした場合にinactiveとする(カスタムページは企業オプションもチェック)
            return (
                this.isInactiveStoreLocations &&
                this.isInactiveStoreAttributes &&
                this.isInactiveOpenInfo &&
                this.isInactivePlaceActionLinks &&
                this.isInactiveStructuredInfo &&
                this.isInactiveStoreRivals &&
                (this.isInactiveCustomPage || !this.company.options.includes("hosting"))
            );
        },
        isInactiveStoreLocations(): boolean {
            // 店舗情報タブ
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            return currentRole?.functions?.includes("gmbLocation:inactive-menu");
        },
        isInactiveStoreAttributes(): boolean {
            // 店舗属性情報
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            return currentRole?.functions?.includes("gmbAttribute:inactive-menu");
        },
        isInactiveOpenInfo(): boolean {
            // 店舗情報・営業情報
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            return currentRole?.functions?.includes("gmbOpenInfo:inactive-menu");
        },
        isInactivePlaceActionLinks(): boolean {
            // 店舗情報・アクションリンク
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            return currentRole?.functions?.includes("gmbPlaceActionLink:inactive-menu");
        },
        isInactiveStructuredInfo(): boolean {
            // 構造化データ
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            const functionsToCheck = [
                "structuredInfo:inactive-menu",
                "localBusinessInfo:inactive-menu",
            ];
            return functionsToCheck.every((func) => currentRole?.functions?.includes(func));
        },
        isInactiveStoreRivals(): boolean {
            // 競合店舗情報
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            return currentRole?.functions?.includes("localBusinessRivals:inactive-menu");
        },
        isInactiveCustomPage(): boolean {
            // カスタム店舗ページ
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            return currentRole?.functions?.includes("storesInfo:inactive-menu");
        },
        isInactiveMediaLink(): boolean {
            // 基本情報と属性情報のどちらかがinActiveの場合はTrue(inActive＝権限がない、この機能では両方の権限が必要)
            return this.isInactiveStoreLocations || this.isInactiveStoreAttributes;
        },
        isInactiveAppleInfo(): boolean {
            // Apple連携がされていて、基本情報がinactiveだったらtrue
            return this.isInactiveStructuredInfo && this.appleEnabled;
        },
        isInactiveStoreUpdate(): boolean {
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            return currentRole?.functions?.includes("storeupdates:inactive-menu");
        },
        isInactiveMedia(): boolean {
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            return currentRole?.functions?.includes("media:inactive-menu");
        },
        isInactiveMediaHistories(): boolean {
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            return currentRole?.functions?.includes("mediaHistories:inactive-menu");
        },
        isInactiveMenu(): boolean {
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            return currentRole?.functions?.includes("menu:inactive-menu");
        },
        isInactiveApple(): boolean {
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            return currentRole?.functions?.includes("apple:inactive-menu");
        },
        isInactiveCitationSettings(): boolean {
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            // 企業のサイテーション設定でFacebookまたはInstagramが有効な場合だけ表示したい
            // FIXME: Facebook, Instagram以外の連携先が増えた場合に判定を増やさないといけないので良い方法はないか？
            return (
                currentRole?.functions?.includes("facebookauth:inactive-menu") &&
                (this.facebookEnabled || this.instagramEnabled)
            );
        },
        isInactiveCitationSettingsYahoo(): boolean {
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            return currentRole?.functions?.includes("yahooauth:inactive-menu") && this.yahooEnabled;
        },
        isInactiveCitationSettingsApple(): boolean {
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            return currentRole?.functions?.includes("appleauth:inactive-menu") && this.appleEnabled;
        },
        canShowHealthCheck(): boolean {
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            return currentRole?.functions?.includes("healthCheck:read");
        },
        canShowSummary(): boolean {
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            return currentRole?.functions?.includes("metrics:read");
        },
        canShowCompanyOwner(): boolean {
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            return currentRole?.functions?.includes("companyconfig:update");
        },
        canShowCompany(): boolean {
            // 企業の作成権限
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            return currentRole?.functions?.includes("companies:create");
        },
        canShowOwnCompany(): boolean {
            // 自身の所属企業の書き込み権限
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            return currentRole?.functions?.includes("companyconfig:update");
        },
        canShowStore(): boolean {
            // 店舗一覧の書き込み権限権限
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            return currentRole?.functions?.includes("stores:update");
        },
        canShowGroup(): boolean {
            // 店舗設定管理の書き込み権限権限
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            return currentRole?.functions?.includes("groups:update");
        },
        canShowUser(): boolean {
            // ユーザー一覧の書き込み権限権限
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            return currentRole?.functions?.includes("user:update");
        },
        canShowProfile(): boolean {
            // 自身のプロファイル読み込み権限
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            return currentRole?.functions?.includes("myself:update");
        },
        canShowCustomRoles(): boolean {
            // 権限管理の読み込み権限
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            return currentRole?.functions?.includes("roles:create");
        },
        canShowArea(): boolean {
            // グループ一覧の書き込み権限権限
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            return currentRole?.functions?.includes("groups:update");
        },
        canShowStoreArea(): boolean {
            // 店舗管理の書き込み権限権限
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            return currentRole?.functions?.includes("stores:update");
        },
        canShowBeta(): boolean {
            // ベータ機能の読み込み権限
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            return currentRole?.functions?.includes("beta:read");
        },
        canShowReview(): boolean {
            // 口コミ読み込み権限
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            return currentRole?.functions?.includes("reviews:read");
        },
        canShowPost(): boolean {
            // 投稿読み込み権限
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            return currentRole?.functions?.includes("post:read");
        },
        canShowReport(): boolean {
            // レポート一覧表示権限
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            return currentRole?.functions?.includes("report:read");
        },
        canShowStoreLocation(): boolean {
            // 店舗情報・基本情報
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            // いずれかの権限があれば表示する
            const functionsToCheck = [
                "gmbLocation:read",
                "gmbAttribute:read",
                "gmbOpenInfo:read",
                "gmbPlaceActionLink:read",
                "structuredInfo:read",
                "localBusinessInfo:read",
                "localBusinessRivals:read",
                "storesInfo:read",
            ];
            return functionsToCheck.some((func) => currentRole?.functions?.includes(func));
        },
        canShowMediaLink(): boolean {
            // 詳細画面(メディアリンク)
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            // 両方の権限があれば表示する
            const functionsToCheck = ["gmbLocation:read", "gmbAttribute:read"];
            return functionsToCheck.every((func) => currentRole?.functions?.includes(func));
        },
        canShowAppleInfo(): boolean {
            // ABC情報
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            // GBPの基本情報が閲覧でき、かつAppleの連携が有効なら表示する
            return currentRole?.functions?.includes("gmbLocation:read") && this.appleEnabled;
        },
        canShowStoreRivals(): boolean {
            // 競合店舗情報
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);

            return currentRole?.functions?.includes("localBusinessRivals:read");
        },
        canShowCustomPage(): boolean {
            // カスタム店舗ページ
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            return currentRole?.functions?.includes("storesInfo:create");
        },
        canShowStoreUpdate(): boolean {
            // 変更提案
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            return currentRole?.functions?.includes("storeupdates:read");
        },
        canShowMedia(): boolean {
            // 画像・動画
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            return currentRole?.functions?.includes("media:read");
        },
        canShowMenu(): boolean {
            // メニュー
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            return currentRole?.functions?.includes("menu:read");
        },
        hasFullCompanyAccess(): boolean {
            // 企業全体を表示する権限
            return !this.canShowAllowedAreasOnly && !this.canShowAllowedStoresOnly;
        },
        canShowAllowedAreasOnly(): boolean {
            // 自身が所属するエリアのみを表示させる場合にtrueを返す
            if (this.user == null) {
                return true;
            }
            const role = this.roleList.filter((r) => r.roleLv == this.user.roleLv);
            if (role.length === 1) {
                switch (role[0].viewRange) {
                    case 1: // administrator(管理権限)
                        return false;
                    case 2: // companies(パワーユーザー)
                        return false;
                    case 3: // company(企業オーナー)
                        return false;
                    case 4: // groups(グループ所属ユーザー)
                        return true;
                    case 5: // stores(店舗所属ユーザー)
                        return false;
                }
            }
            return true;
        },
        canShowAllowedStoresOnly(): boolean {
            // 自身が権限を持っている店舗のみを表示させる場合にtrueを返す
            if (this.user == null) {
                return true;
            }
            const role = this.roleList.filter((r) => r.roleLv == this.user.roleLv);
            if (role.length === 1) {
                switch (role[0].viewRange) {
                    case 1: // administrator(管理権限)
                        return false;
                    case 2: // companies(パワーユーザー)
                        return false;
                    case 3: // company(企業オーナー)
                        return false;
                    case 4: // groups(グループ所属ユーザー)
                        return false;
                    case 5: // stores(店舗所属ユーザー)
                        return true;
                }
            }
            return false;
        },
        facebookEnabled(): boolean {
            return "citation" in this.company &&
                this.company.citation &&
                "facebook" in this.company.citation &&
                "enabled" in this.company.citation["facebook"]
                ? this.company.citation["facebook"]["enabled"]
                : false;
        },
        instagramEnabled(): boolean {
            return "citation" in this.company &&
                this.company.citation &&
                "instagram" in this.company.citation &&
                "enabled" in this.company.citation["instagram"]
                ? this.company.citation["instagram"]["enabled"]
                : false;
        },
        yahooEnabled(): boolean {
            return this.company.citation?.yahooplace?.enabled ?? false;
        },
        appleEnabled(): boolean {
            return this.company.citation?.apple?.enabled ?? false;
        },
        structuredIDEdit(): boolean {
            return this.company?.custom?.structuredIDEdit ?? false;
        },
        canShowCitationSettings(): boolean {
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            // 企業のサイテーション設定でFacebookまたはInstagramが有効な場合だけ表示したい
            // FIXME: Facebook, Instagram以外の連携先が増えた場合に判定を増やさないといけないので良い方法はないか？
            return (
                currentRole?.functions?.includes("facebookauth:read") &&
                (this.facebookEnabled || this.instagramEnabled)
            );
        },
        canShowCitationSettingsYahoo(): boolean {
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            return currentRole?.functions?.includes("yahooauth:read") && this.yahooEnabled;
        },
        canShowCitationSettingsApple(): boolean {
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            return currentRole?.functions?.includes("appleauth:read") && this.appleEnabled;
        },
        canSelectFacebookAccount(): boolean {
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            return currentRole?.functions?.includes("facebookauth:select-account");
        },
        canManageStoreLocations(): boolean {
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);

            const functionsToCheck = [
                "gmbLocation:create",
                "gmbLocation:update",
                "gmbLocation:delete",
            ];
            return functionsToCheck.some((func) => currentRole?.functions?.includes(func));
        },
        canManageStoreAttributes(): boolean {
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);

            const functionsToCheck = [
                "gmbAttribute:create",
                "gmbAttribute:update",
                "gmbAttribute:delete",
            ];
            return functionsToCheck.some((func) => currentRole?.functions?.includes(func));
        },
        canManageOpenInfo(): boolean {
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);

            const functionsToCheck = [
                "gmbOpenInfo:create",
                "gmbOpenInfo:update",
                "gmbOpenInfo:delete",
            ];
            return functionsToCheck.some((func) => currentRole?.functions?.includes(func));
        },
        canManagePlaceActionLinks(): boolean {
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);

            const functionsToCheck = [
                "gmbPlaceActionLink:create",
                "gmbPlaceActionLink:update",
                "gmbPlaceActionLink:delete",
            ];
            return functionsToCheck.some((func) => currentRole?.functions?.includes(func));
        },
        canManageStructuredInfo(): boolean {
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);

            const functionsToCheck = [
                "structuredInfo:create",
                "structuredInfo:update",
                "structuredInfo:delete",
                "localBusinessInfo:create",
                "localBusinessInfo:update",
                "localBusinessInfo:delete",
            ];
            return functionsToCheck.some((func) => currentRole?.functions?.includes(func));
        },
        canManageStoreRivals(): boolean {
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);

            const functionsToCheck = [
                "localBusinessRivals:create",
                "localBusinessRivals:update",
                "localBusinessRivals:delete",
            ];
            return functionsToCheck.some((func) => currentRole?.functions?.includes(func));
        },
        canManageCustomPage(): boolean {
            console.log("canManageCustomPage");
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);

            return currentRole?.functions?.includes("storesInfo:update");
        },
        canManageMediaLink(): boolean {
            // 基本情報と属性情報の両方の権限が必要
            return this.canManageStoreLocations && this.canManageStoreAttributes;
        },
        canManageStoreUpdate(): boolean {
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);

            return currentRole?.functions?.includes("storeupdates:approve");
        },
        canManageMedia(): boolean {
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);

            const functionsToCheck = ["media:create", "media:request", "media:approve"];
            return functionsToCheck.some((func) => currentRole?.functions?.includes(func));
        },
        canPostCreate(): boolean {
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            return (
                currentRole?.functions?.includes("post:request") ||
                currentRole?.functions?.includes("post:create")
            );
        },
        canPostEdit(): boolean {
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            return (
                currentRole?.functions?.includes("post:request") ||
                currentRole?.functions?.includes("post:update")
            );
        },
        canPostDelete(): boolean {
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            return (
                currentRole?.functions?.includes("post:request") ||
                currentRole?.functions?.includes("post:delete")
            );
        },
        canPostApprove(): boolean {
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            return currentRole?.functions?.includes("post:approve");
        },
        canPostRequest(): boolean {
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);
            return currentRole?.functions?.includes("post:request");
        },
        canManagePost(): boolean {
            return (
                this.canPostCreate ||
                this.canPostEdit ||
                this.canPostDelete ||
                this.canPostApprove ||
                this.canPostRequest
            );
        },
        canManageMenu(): boolean {
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);

            const functionsToCheck = [
                "menu:create",
                "menu:update",
                "menu:delete",
                "menu:request",
                "menu:approve",
            ];
            return functionsToCheck.some((func) => currentRole?.functions?.includes(func));
        },
        canManageReviewReply(): boolean {
            if (this.user == null) {
                return false;
            }
            const currentRole = this.roleList?.find((r) => r.roleLv === this.user.roleLv);

            // note: createはレポート出力に使っていて返信には使っていないので除外している
            const functionsToCheck = [
                "reviews:update",
                "reviews:delete",
                "reviews:request",
                "reviews:approve",
            ];

            return functionsToCheck.some((func) => currentRole?.functions?.includes(func));
        },
    },
    actions: {
        /** 初期化されていなければ初期化する */
        async initIfNotInitialized(): Promise<void> {
            if (this.storeRestored) {
                return;
            }
            // localForage で IndexedDB を用いる
            localForage.config({
                driver: localForage.INDEXEDDB,
                name: "tostore-ls",
            });
            await this.readIndexedDb();
            this.$subscribe(subscriber, { detached: true });
        },
        /** IndexedDB から情報を復元する */
        async readIndexedDb(): Promise<void> {
            for (const key of Object.keys(this.$state)) {
                if (notPersistKeys.includes(key)) {
                    continue;
                }
                const stored = await localForage.getItem(key);
                if (stored !== null) {
                    this.$patch({ [key]: stored });
                }
            }
            this.$patch({ storeRestored: true });
            // console.log("readIndexedDb", this.isDrawerOpened);
        },
        clear(): void {
            this.postRow = null;
            this.isDrawerOpened = true;
            this.selectReport = {
                selectReportType: "other",
                selectReportRange: null,
                selectStore: null,
            };
        },
        setAuth(auth: ControllersLoginOutput): void {
            sessionStorage.IdToken = auth.idToken;
            sessionStorage.AccessToken = auth.accessToken;
            sessionStorage.RefreshToken = auth.refreshToken;
            this.user = auth.user;
            this.company = auth?.company;
            this.poiGroupId = auth?.company?.poiGroupID;
        },
        setUser(user: EntitiesUser): void {
            this.user = user;
        },
        setDrawerMenu(val: boolean): void {
            this.isDrawerOpened = val;
        },
        setIsShowAllStores(isShow: boolean): void {
            this.isShowAllStores = isShow;
        },
        setIsMobile(val: boolean): void {
            this.isMobile = val;
        },
        async setRoles(poiGroupId: number): Promise<void> {
            const roles = (
                await requiredAuth<ControllersRolesFindAllOutput>(
                    "get",
                    `${import.meta.env.VITE_APP_API_BASE}v1/companies/${poiGroupId}/roles`
                )
            ).data;
            this.roleList.splice(0, this.roleList.length, ...(roles?.roles?.roles || []));
        },
        async setAreas(poiGroupId: number): Promise<void> {
            const res = await requiredAuth<EntitiesAreasResponse>(
                "get",
                `${import.meta.env.VITE_APP_API_BASE}v1/companies/${poiGroupId}/areas`,
                null,
                null,
                null,
                { statusCode: 403 } as CustomSnackbarToast
            );
            if (res.status === 200) {
                const areas = res.data.areas;
                const areaDictionary: Record<string, string> = {};
                areas.map((area) => (areaDictionary[area.areaID] = area.name));
                this.areas = areaDictionary;
            } else if (res.status === 403) {
                // 権限がない場合は店舗参照権限だけなので、正常終了させる
                this.areas = {};
            } else {
                throw res;
            }
        },
        async setAreaStores(poiGroupId: number): Promise<void> {
            const res = await requiredAuth<EntitiesAreaStoresResponse>(
                "get",
                `${import.meta.env.VITE_APP_API_BASE}v1/companies/${poiGroupId}/areas/all`,
                null,
                null,
                null,
                { statusCode: 403 } as CustomSnackbarToast
            );
            if (res.status === 200) {
                this.areaStores = res.data.areaStores;
            } else if (res.status === 403) {
                // 権限がない場合は店舗参照権限だけなので、店舗の情報を入れて正常終了させる
                this.areaStores = [{ stores: this.stores.stores }];
            } else {
                throw res;
            }
        },
        async setCompany(poiGroupId: number): Promise<void> {
            const company = (
                await requiredAuth<EntitiesCompaniesResponse>(
                    "get",
                    `${import.meta.env.VITE_APP_API_BASE}v1/companies/${poiGroupId}`
                )
            ).data.companies[0];
            if (company.poiGroupID == null) {
                company.poiGroupID = 0;
            }
            this.company = company;
            this.poiGroupId = poiGroupId;
        },
        async setStores(poiGroupId: number): Promise<void> {
            const stores = (
                await requiredAuth(
                    "get",
                    `${import.meta.env.VITE_APP_API_BASE}v1/companies/${poiGroupId}/stores`
                )
            ).data;
            this.stores = stores;
        },
        setPostRow(val: EntitiesV2HistoryPostData): void {
            this.postRow = val;
        },
        setIsShowAllCompanies(isShow: boolean): void {
            this.isShowAllCompanies = isShow;
        },
        clearAuth(): void {
            sessionStorage.IdToken = null;
            sessionStorage.AccessToken = null;
            sessionStorage.RefreshToken = null;
            this.user = null;
        },
        setStoresCurrentPage(currentPage: number): void {
            this.storesCurrentPage = currentPage;
        },
        async getCategories(
            forceUpdate: boolean = false
        ): Promise<MybusinessbusinessinformationCategory[]> {
            if (0 < this.categories.length && !forceUpdate) {
                return this.categories;
            }
            // カテゴリ一覧を取得する
            const catRes = await requiredAuth<MybusinessbusinessinformationListCategoriesResponse>(
                "get",
                `${import.meta.env.VITE_APP_API_BASE}v1/categories`
            );
            const categories: MybusinessbusinessinformationCategory[] =
                (typeof catRes.data === "string" ? JSON.parse(catRes.data) : catRes.data)
                    .categories ?? [];
            // displayNameが重複する場合は "自動車販売店(car_dealer)" のようにする
            // displayName毎の出現数を数える
            const displayNameSet: { [displayName: string]: number } = {};
            for (const c of categories) {
                if (displayNameSet[c.displayName]) {
                    c.displayName = `${c.displayName}(${c.name?.split(":")[1]})`;
                } else {
                    displayNameSet[c.displayName] = 1;
                }
            }
            this.categories = categories;
            return categories;
        },
        async getAttributeMetadata(
            categoryId: string,
            forceUpdate: boolean = false
        ): Promise<MybusinessbusinessinformationAttributeMetadata[]> {
            if (
                this.attributeMetadata[categoryId] &&
                0 < this.attributeMetadata[categoryId].length &&
                !forceUpdate
            ) {
                return this.attributeMetadata[categoryId];
            }
            const attres =
                await requiredAuth<MybusinessbusinessinformationListAttributeMetadataResponse>(
                    "get",
                    `${import.meta.env.VITE_APP_API_BASE}v1/attributes?${categoryId
                        .replace("categories/", "")
                        .replace(":", "=")}`
                );
            const attributes = attres.data?.attributeMetadata ?? [];
            this.attributeMetadata[categoryId] = attributes;
            return attributes;
        },
    },
});

/** useIndexedDb の戻り値の型 */
type UseIndexedDbReturn = ReturnType<typeof useIndexedDb>;

type extractState<Type> = Type extends _StoreWithState<string, infer S, any, any> ? S : never;
type extractStateAndGetter<Type> = Type extends _StoreWithState<string, infer S, infer G, any>
    ? S & getters<G>
    : never;
type extractAction<Type> = Type extends _StoreWithState<string, any, any, infer A> ? A : never;
type getters<T> = { readonly [k in keyof T]: T[k] extends () => infer R ? R : never };
export type State = extractState<UseIndexedDbReturn>;
export type StateAndGetter = extractStateAndGetter<UseIndexedDbReturn>;
export type Action = extractAction<UseIndexedDbReturn>;

/**
 * vuex-classの '@Getter' の置き換えのための関数
 * vue-class-component のフィールド定義で用いることを想定している。
 *
 * storeToRefs(useIndexedDb()) の結果 の StoreToRefs<State> を State に偽装して返している。
 * クラスで定義したフィールドは、Ref が unwrap されるので、フィールドの型は unwrap したもので定義したほうが都合が良い
 */
export function getter(): StateAndGetter {
    const r = storeToRefs(useIndexedDb());
    return r as unknown as StateAndGetter;
}

/**
 * vuex-classの '@Action' の置き換えのための関数
 * vue-class-component のフィールド定義で用いることを想定している。
 *
 * 実体は useIndexedDb() の戻り値をそのまま返しているだけ
 */
export function action(): Action {
    return useIndexedDb();
}

/** state 更新時に IndexedDB も更新するためのサブスクライバ */
const subscriber: SubscriptionCallback<State> = (
    mutation: SubscriptionCallbackMutation<State>,
    state: State
) => {
    // console.log("subscriber", mutation, state);
    for (const key of Object.keys(state)) {
        if (notPersistKeys.includes(key)) {
            continue;
        }
        if (state[key] !== undefined) {
            // Pinia の Proxy オブジェクトから通常のオブジェクトに変換する
            const obj = JSON.parse(JSON.stringify(state[key]));
            localForage.setItem(key, obj);
        }
    }
};
