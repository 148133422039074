<template>
  <div>
    <v-card class="ma-3">
      <v-card-text><p class="text-h5">Apple Business Connect（ABC）連携設定</p></v-card-text>
    </v-card>
    <div class="d-flex flex-wrap ml-3">
      <v-btn
        :disabled="loading || exportLoading"
        class="primary"
        prepend-icon="mdi-download"
        text="XLSXエクスポート"
        @click="exportXlsx"
      />
      <input ref="importInput" type="file" hidden @change="importXlsx" />
      <v-btn
        :disabled="loading"
        class="white"
        prepend-icon="mdi-upload"
        text="XLSXインポート"
        @click="($refs.importInput as HTMLInputElement).click()"
      />
    </div>
    <v-card class="ma-3">
      <v-card-text>
        <p class="text-h6 mb-2">{{ appName }}店舗とABC店舗の紐づけを管理</p>
        <v-text-field
          v-model="search"
          label="Search (店舗ID, 店舗コード, 店舗名)"
          prepend-inner-icon="mdi-magnify"
          variant="outlined"
          hide-details
          single-line
          density="compact"
          class="mb-3"
        />
        <v-data-table
          :items="stores"
          :headers="[
            { title: '店舗ID', key: 'poiID' },
            { title: '店舗コード', key: 'gmbStoreCode' },
            { title: '店舗名', key: 'name' },
            { title: 'ABC店舗名', key: 'appleBusinessConnect.locationId' },
            { title: '編集/紐づけ解除', key: 'actions', sortable: false },
          ]"
          :search="search"
          density="compact"
          :items-per-page="100"
        >
          <template #item.appleBusinessConnect.locationId="{ item }">
            {{ getLocationName(item) }}
          </template>
          <template #item.actions="{ item }">
            <v-btn
              v-if="locationDialog.items"
              data-testid="bind-location"
              size="x-small"
              icon="fas fa-pencil"
              @click="
                locationDialog.poiId = item.poiID;
                locationDialog.name = item.name;
                locationDialog.value = item?.appleBusinessConnect?.locationId;
                locationDialog.show = true;
              "
            />
            <span v-if="item.appleBusinessConnect?.locationId">
              <v-btn
                data-testid="unbind-location"
                size="x-small"
                icon="fas fa-plug-circle-xmark"
                @click="
                  unbindlocationDialog.poiId = item.poiID;
                  unbindlocationDialog.name = item.name;
                  unbindlocationDialog.show = true;
                "
              />
            </span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>

  <!-- 店舗選択ダイアログ -->
  <v-dialog v-model="locationDialog.show" width="677" persistent class="uploading-dialog">
    <v-card>
      <v-card-title class="headline grey lighten-2" primary-title>ABC店舗選択</v-card-title>
      <v-card-text>
        <div class="mb-2">{{ locationDialog.name }}</div>
        <v-autocomplete
          v-model="locationDialog.value"
          class="selector"
          clearable
          :items="locationDialog.items"
          :multiple="false"
          item-title="title"
          item-value="id"
          label="紐づけるABC店舗を選択してください"
          single-line
          hide-details
        ></v-autocomplete>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="locationDialog.show = false">キャンセル</v-btn>
        <v-btn depressed color="primary" :disabled="!locationDialog.value" @click="bindStore()">
          登録
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- 店舗紐づけ解除確認ダイアログ -->
  <v-dialog v-model="unbindlocationDialog.show" max-width="600">
    <confirm-dialog
      message="ABC店舗の紐づけを解除しますか？"
      :name="unbindlocationDialog.name"
      cancel-button="キャンセル"
      submit-button="紐づけを解除する"
      @cancel="unbindlocationDialog.show = false"
      @submit="unbindStore(unbindlocationDialog.poiId)"
    />
  </v-dialog>

  <!-- 結果ダイアログ -->
  <v-dialog v-model="resultDialog.show" max-width="500">
    <v-card>
      <v-card-title class="headline">XLSXインポート</v-card-title>
      <v-card-text>
        <!-- eslint-disable-next-line -->
        <div v-html="resultDialog.message" />
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" variant="text" @click="resultDialog.show = false">閉じる</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <div v-if="loading" class="progress-circular-container">
    <v-progress-circular :size="80" :width="4" color="primary" indeterminate></v-progress-circular>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ConfirmDialog from "@/components/root/contents/food-menu/confirm-dialog.vue";
import { useIndexedDb } from "@/storepinia/idxdb";
import { currentTheme } from "@/components/shared/theme";
import type { EntitiesStore } from "@/types/ls-api";
import { api as aapi } from "@/helpers/api/apple";
import { useSnackbar } from "@/storepinia/snackbar";
import wordDictionary from "@/word-dictionary";
import dayjs from "dayjs";
import { arrayBufferToStringsArrays, read } from "@/helpers/xlsxtools";
import * as XLSX from "xlsx";
import { isAxiosError } from "axios";
import pLimit from "p-limit";
export default defineComponent({
  components: { ConfirmDialog },
  data: () => {
    return {
      loading: false,
      exportLoading: false,
      company: useIndexedDb().company,
      appleDialog: { show: false },
      appName: currentTheme().appName as string,
      isComUser: useIndexedDb().isComUser,
      stores: [] as EntitiesStore[],
      search: "",
      connectDialog: { show: false },
      disconnectDialog: { show: false },
      locationDialog: {
        show: false,
        poiId: 0,
        name: "",
        value: "",
        items: [] as { id: string; title: string }[],
      },
      unbindlocationDialog: { show: false, poiId: 0, name: "" },
      resultDialog: { show: false, message: "" },
      idToNameDict: new Map<string, string>(),
      nameToIdDict: new Map<string, string>(),
      dupulicateNameDict: new Map<string, string>(), // 重複店舗
    };
  },
  computed: {
    poiGroupID: function (): number {
      return parseInt(this.$route.params.poiGroupId as string);
    },
  },
  async mounted() {
    try {
      this.loading = true;
      await this.getStores();
      await this.getLocations();
    } catch (e: any) {
      useSnackbar().addSnackbarMessages({
        text: `店舗一覧の取得に失敗しました。リロードしてください。 ${this.message(e)}`,
        color: "danger",
      });
    } finally {
      this.loading = false;
    }
  },
  methods: {
    async getStores() {
      // toSTORE店舗一覧取得
      // Vuexに既にstores保持していたらそちらを使う
      let storeData: EntitiesStore[];
      if (useIndexedDb().stores?.stores) {
        storeData = useIndexedDb().stores.stores;
      } else {
        storeData = await aapi.listStores(this.poiGroupID);
      }
      this.stores = (storeData ?? []).filter((s) => s.enabled && s.options?.includes("apple"));
    },
    async getLocations() {
      // Apple Business Connect 店舗一覧取得
      let after = null;
      let allLocations = [];

      do {
        const res = await aapi.listAppleLocations(this.poiGroupID, after);
        if (res.error) {
          useSnackbar().addSnackbarMessages({
            text: `Code:${res.statusCode} ${res.error.map((e) => e.message).join(", ")}`,
            color: "danger",
          });
          return;
        }

        const locations =
          res.data?.data.map((loc) => ({
            id: `companies/${loc.companyId}/locations/${loc.id}`,
            title: loc.locationDetails?.displayNames[0]?.name ?? "不明な店舗名",
          })) ?? [];

        allLocations = allLocations.concat(locations);
        after = res.data?.pagination?.cursors?.after ?? null;
      } while (after);

      this.locationDialog.items = allLocations;

      this.locationDialog.items?.forEach((loc) => {
        this.idToNameDict[loc.id] = loc.title;
        // 重複している店舗名をチェック
        if (this.nameToIdDict[loc.title]) {
          this.dupulicateNameDict[loc.title] = loc.id;
        } else {
          this.nameToIdDict[loc.title] = loc.id;
        }
      });
    },
    getLocationName(store: EntitiesStore): string {
      const apple = store?.appleBusinessConnect;
      if (!apple || !apple.locationId) {
        return "-"; // 紐づけされてない
      }
      return this.idToNameDict[apple?.locationId] ?? (this.loading ? "..." : "不明なABC店舗名");
    },
    async bindStore() {
      const poiId = this.locationDialog.poiId;
      this.locationDialog.show = false;

      try {
        this.loading = true;
        const s = await aapi.putStoreApple(this.$route, this.poiGroupID, poiId, {
          locationId: this.locationDialog.value,
        });
        this.stores
          .filter((store) => store.poiID === poiId)
          .forEach((store) => {
            store.appleBusinessConnect = s.appleBusinessConnect;
          });
      } catch (e: any) {
        useSnackbar().addSnackbarMessages({
          text: `店舗の紐づけに失敗しました。${this.message(e)}`,
          color: "danger",
        });
      } finally {
        this.loading = false;
      }
    },
    async unbindStore(poiId: number) {
      try {
        this.loading = true;
        this.unbindlocationDialog.show = false;
        const s = await aapi.putStoreApple(this.$route, this.poiGroupID, poiId, {});
        const store = this.stores.find((store) => store.poiID === poiId);
        store.appleBusinessConnect = s.appleBusinessConnect;
      } catch (e: any) {
        useSnackbar().addSnackbarMessages({
          text: `店舗の紐づけ解除に失敗しました。${this.message(e)}`,
          color: "danger",
        });
      } finally {
        this.loading = false;
      }
    },
    async importXlsx(event: Event) {
      const errorRows: string[] = [];
      let successCount = 0;

      // 店舗一覧XLSXを読み込んで、店舗の紐づけを更新する
      try {
        this.loading = true;
        const target = event.target as HTMLInputElement;
        const xlsxFile: File = target.files[0];
        if (!xlsxFile) {
          console.error("XLSXファイルが選択されていません。");
          return;
        }
        const buf = await read(xlsxFile as any);
        const book = arrayBufferToStringsArrays(buf);
        const sheet = book[0];
        const header = sheet[0];
        // ヘッダ行をチェックして想定外ならエラートーストを表示
        if (header.join(",") !== ["店舗ID", "店舗コード", "店舗名", "ABC店舗名"].join(",")) {
          useSnackbar().addSnackbarMessages({
            text: "想定外の形式。本画面でエクスポートしたXLSXファイルを使ってください。",
            color: "warning",
          });
          return;
        }
        const stores = this.stores;
        const limit = pLimit(5); // 最大同時処理数を設定

        const tasks = sheet.slice(1).map((row, i) => {
          const index = i + 2; // slice(1)でヘッダ行を飛ばしたので+2
          return limit(async () => {
            const poiID = row[0];
            const store = stores.find((s) => s.poiID.toString() === poiID);
            const locationName = row[3];

            if (!store) {
              errorRows.push(`<b>${index}行目</b> 店舗ID ${poiID} が見つかりません。`);
              return;
            }
            // ABC上で重複している店舗名は判別できないのでスキップ
            if (this.dupulicateNameDict[locationName]) {
              errorRows.push(
                `<b>${index}行目</b> ABC店舗名 ${locationName} が重複しています。ABCコンソールで重複を解消してください。`
              );
              return;
            }
            let locationId: string = this.nameToIdDict[locationName];
            if (locationName.trim() === "" || locationName === "-") {
              locationId = "";
            } else if (!locationId) {
              errorRows.push(`<b>${index}行目</b> ABC店舗名 ${locationName} が見つかりません。`);
              return;
            }
            if ((store.appleBusinessConnect?.locationId ?? "") === locationId) {
              return;
            }
            try {
              const abc = poiID.trim() === "" || poiID === "-" ? {} : { locationId };
              await aapi.putStoreApple(this.$route, this.poiGroupID, store.poiID, abc);
              successCount++;
              store.appleBusinessConnect = { locationId };
            } catch (e: any) {
              errorRows.push(
                `<b>${index}行目</b> 店舗ID ${poiID} の店舗の紐づけに失敗しました。${this.message(
                  e
                )}`
              );
            }
          });
        });

        // 全てのタスクが完了するのを待機
        await Promise.all(tasks);
      } catch (e: any) {
        useSnackbar().addSnackbarMessages({
          text: `店舗一覧のXLSXインポート、店舗の紐づけの更新に失敗しました。${this.message(e)}`,
          color: "danger",
        });
      } finally {
        this.loading = false;

        this.resultDialog.message = `${successCount}件の店舗の紐づけを更新しました。`;
        if (errorRows.length > 0) {
          this.resultDialog.message +=
            `<br><br><b>${errorRows.length}件の店舗をエラーでスキップしました。</b><br>` +
            errorRows.join("<br>");
        }
        this.resultDialog.show = true;
      }
    },
    async exportXlsx(): Promise<void> {
      try {
        this.exportLoading = true;
        // トストア店舗一覧をXLSXファイルに出力
        const stores = this.stores.map((store) => {
          return {
            店舗ID: store.poiID,
            店舗コード: store.gmbStoreCode,
            店舗名: store.name,
            ABC店舗名: this.getLocationName(store),
          };
        });
        const wsStores = XLSX.utils.json_to_sheet(stores);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, wsStores, "店舗紐づけ一覧");

        // ABC店舗一覧をXLSXファイルに出力
        const locations = this.locationDialog.items.map((loc) => {
          return {
            ABC店舗ID: loc.id,
            ABC店舗名: loc.title,
          };
        });
        const wsLocations = XLSX.utils.json_to_sheet(locations);
        XLSX.utils.book_append_sheet(wb, wsLocations, "ABC店舗一覧");

        const companyName = useIndexedDb().company.name;
        const datetime = dayjs().format("YYYYMMDD");
        const fileName = `${wordDictionary.citationSettingsApple.name}-店舗一覧エクスポート-${companyName}-${datetime}.xlsx`;
        XLSX.writeFile(wb, fileName);
      } catch (e: any) {
        useSnackbar().addSnackbarMessages({
          text: `店舗一覧のXLSXエクスポートに失敗しました。 ${this.message(e)}`,
          color: "danger",
        });
      } finally {
        this.exportLoading = false;
      }
    },
    message(error: Error): string {
      console.error(error);
      if (isAxiosError(error)) {
        return error.response?.data?.errorMessage ?? error.message ?? "不明なメッセージ";
      }
      return error.message;
    },
  },
});
</script>

<style lang="scss" scoped>
div.pagination {
  ul.pagination-list {
    margin: 0;
    list-style: none;
  }
}
.content li + li {
  margin-top: 0 !important;
}
.modal.is-active {
  display: flex;
  z-index: var(--z-index-modal);
}
.error-message {
  color: red;
}
.iconPosition {
  padding-top: 5px;
  padding-left: 10px;
}
.buttonSection {
  text-align: right;
}
.control-display-checkbox {
  width: 220px;
  margin-left: auto;
  text-align: right;
  margin-right: 10px;
}
</style>
