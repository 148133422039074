import { requiredAuth } from "@/helpers";
import { Component, Vue, toNative, Watch } from "vue-facing-decorator";
import type { EntitiesCompaniesResponse, EntitiesCompany } from "@/types/ls-api";
import wordDictionary from "@/word-dictionary";
import { useSnackbar } from "@/storepinia/snackbar";
import { isAxiosError } from "axios";
import { getOperationLogParams } from "@/routes/operation-log";
import dayjs from "dayjs";
import { getter, action } from "@/storepinia/idxdb";
import type { FormContext } from "vee-validate";
import { useDialog } from "@/storepinia/dialog";
import type { ErrorDialogMessage } from "@/components/shared/review-shared";
import { currentTheme } from "@/components/shared/theme";
import AutoCompleteCard from "@/components/shared/auto-complete-card/AutoCompleteCard.vue";
import { api as aapi } from "@/helpers/api/apple";

/** v-combobox設定用クラス */
class ComboBoxSettings {
    items: string[] = [];
    max = -1;
    constructor(max = -1) {
        this.max = max;
    }
}

/** v-autocomplete と v-select の選択肢 */
export interface SelectItem {
    isHeader: boolean;
    title: string;
    id?: bigint; // ブランドIDの桁数が大きいためbigint型
    keyword?: string; // 検索用キーワード
}

@Component({ components: { AutoCompleteCard } })
class Form extends Vue {
    declare $refs: { observer: FormContext };
    canShowCompany = getter().canShowCompany;
    user = getter().user;
    viewRange = getter().userViewRange;
    canShowCompanyOwner = getter().canShowCompanyOwner;
    setCompany = action().setCompany;
    addSnackbarMessages = useSnackbar().addSnackbarMessages;

    appName: string = currentTheme().appName;

    defaultRadius: number = 30;
    company: Partial<EntitiesCompany> = {
        name: "",
        scoreType: 0,
        isActive: true,
        useCustomerGbpToken: false,
        gmbAccount: "",
        poiGroupID: null,
        defaultRadius: this.defaultRadius,
        ipWhitelist: [],
        keywords: [],
        searchKeywordVolumeKeywords: [],
        useOptGbpAccount: false,
    };

    isNew: boolean = true;
    message: string = "";
    isLoading: boolean = false;
    isLoadingApple: boolean = false;
    dict = wordDictionary.company_form;
    gmbAccounts: { id: string; name: string }[] = [];
    formRadius: string = this.defaultRadius.toString();
    ipWhitelist: { id: string; name: string }[] = [];

    // サイテーション関係のパラメータ
    facebook: boolean = false;
    instagram: boolean = false;
    yplace: boolean = false;
    apple: boolean = false;

    appleBrandsItems: SelectItem[] = [];
    appleBrands: bigint[] = [];
    appleBrandsSelectLabel: string = "";
    // AppleブランドのIDと名前のマップ
    appleBrandsMap = new Map<bigint, { id: string; name: string }>();

    // 店舗ページホスティング関連のパラメータ
    useReferersSetting: boolean = false;
    referers: string[] = [];
    showReferersNote: boolean = false;
    hosting: boolean = false;
    storePageHostingS3Path: string = "";
    storePageHostingPublicRootPath: string = "";
    storePageHostingImagePath: string = "";
    storePageHostingCloudfrontDistributionID: string = "";
    storePageHostingCloudfrontDistributionPath: string = "";
    canEditStructuredID: boolean = false;
    hostingTrafficLimit: number = 0;
    hostingTrafficLimitUnit = "KB";
    hostingTrafficLimitOldUnit = "KB";
    unitList = ["KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    // 変更提案の自動ブロックのパラメータ
    autoBlock: boolean = false;
    beforeAutoBlock: boolean = false; // 画面表示時の初期値
    autoBlockInfo: string = "「変更提案を自動で元に戻す」は未設定。";
    confirmAutoBlock: boolean = false;
    isConsented: boolean = false;

    // Google Business Profile画像・動画の縦横比チェックのパラメータ
    allowAnyAspectRatio: boolean = false;
    beforeAllowAnyAspectRatio: boolean = false;
    allowAnyAspectRatioInfo: string =
        "「Google Business Profile画像・動画の縦横比チェック」は未設定。";
    confirmAllowAnyAspectRatio: boolean = false;
    isConsentedAspectRatio: boolean = false;

    // Optionsパラメータ
    v2_post: boolean = false;
    reviews_disabled: boolean = false;

    // 投稿承認オプション
    v2_post_approval_manual: boolean = false;

    // 検索キーワードのコンボボックス
    companyKeywordSettings = new ComboBoxSettings(4);
    // 検索キーワードボリュームのコンボボックス
    searchKeywordVolumeKeywordsSettings = new ComboBoxSettings(15);
    // 接続を許可するIPアドレスのコンボボックス
    allowedIPSettings = new ComboBoxSettings(15);
    // 業種・業態のコンボボックス
    businessTypeSettings = new ComboBoxSettings();
    // ホスティングの流入元制限設定のコンボボックス
    referersSettings = new ComboBoxSettings(15);

    async mounted(): Promise<void> {
        this.isLoading = true;
        this.isLoadingApple = true;
        await Promise.all([this.getCompanies(), this.getAppleBrands()]);
    }

    async getCompanies(): Promise<void> {
        try {
            const poiGroupId = parseInt(this.$route?.params?.poiGroupId as string, 10);
            this.isNew = isNaN(poiGroupId);

            if (this.isNew) {
                const resGmbAccounts = await requiredAuth<EntitiesCompaniesResponse>(
                    "get",
                    `${import.meta.env.VITE_APP_API_BASE}v1/companies`,
                    { noregist: true }
                );

                if (resGmbAccounts == null || resGmbAccounts.data == null) {
                    this.gmbAccounts = [];
                } else {
                    this.gmbAccounts = resGmbAccounts.data.companies.map((company) => ({
                        id: company.gmbAccount,
                        name: `${company.name} : ${company.gmbAccount}`,
                    }));
                }
                return;
            }

            const resCompanies = await requiredAuth<EntitiesCompaniesResponse>(
                "get",
                `${import.meta.env.VITE_APP_API_BASE}v1/companies/${poiGroupId}`
            );
            if (resCompanies == null || resCompanies.data == null) {
            } else {
                this.company = resCompanies.data.companies[0];
                this.formRadius = this.defaultRadius.toString();
                if (this.company.defaultRadius) {
                    this.formRadius = this.company.defaultRadius.toString();
                }
                this.company.keywords = this.company.keywords ?? [];
            }
            if (this.company.citation != null) {
                this.facebook =
                    "facebook" in this.company.citation
                        ? this.company.citation["facebook"].enabled
                        : false;
                this.instagram =
                    "instagram" in this.company.citation
                        ? this.company.citation["instagram"].enabled
                        : false;
                this.yplace =
                    "yahooplace" in this.company.citation
                        ? this.company.citation["yahooplace"].enabled
                        : false;
                this.apple =
                    "apple" in this.company.citation
                        ? this.company.citation["apple"].enabled
                        : false;
                (this.company.citation["apple"]?.brands ?? []).map((brand) => {
                    this.appleBrands.push(BigInt(brand.brandId));
                    // 管理者でない場合はブランド一覧を取得しないので、ここで一覧をセットする
                    if (!this.canShowCompany) {
                        this.appleBrandsItems.push({
                            isHeader: false,
                            title: brand.brandName,
                            id: BigInt(brand.brandId),
                        });
                    }
                });
            }
            this.useReferersSetting =
                this.company.options?.includes("hosting") &&
                this.company.custom?.cloudfrontDistributionID?.length > 0;
            this.referers = this.company.hostingReferers ?? [];
            this.hosting = this.company.options?.includes("hosting");
            this.storePageHostingS3Path = this.company.custom?.hostingS3RootPath;
            this.storePageHostingPublicRootPath = this.company.custom?.hostingRootURL;
            this.storePageHostingImagePath = this.company.custom?.hostingImagePath;
            this.storePageHostingCloudfrontDistributionID =
                this.company.custom?.cloudfrontDistributionID;
            this.storePageHostingCloudfrontDistributionPath =
                this.company.custom?.cloudfrontDistributionPath;
            this.canEditStructuredID = this.company.custom?.structuredIDEdit ?? false;
            const r = this.getUnit(this.company.custom?.hostingTrafficLimitPerMonth);
            this.hostingTrafficLimit = r.value ?? 0;
            this.hostingTrafficLimitOldUnit = this.hostingTrafficLimitUnit = r.unit;
            this.autoBlock = this.company.autoBlockUpdates?.googleBusinessProfile ?? false;
            this.beforeAutoBlock = this.autoBlock;
            if (this.company.autoBlockUpdates?.updatedAt) {
                const updateAt = dayjs(this.company.autoBlockUpdates?.updatedAt).format(
                    "YYYY/MM/DD HH:mm:ss"
                );
                const updateUserName = this.company.autoBlockUpdates?.updatedUserName ?? "";

                this.autoBlockInfo = `${updateAt} ${updateUserName} が設定。`;
            }
            this.allowAnyAspectRatio =
                this.company.allowAnyAspectRatio?.googleBusinessProfile ?? false;
            this.beforeAllowAnyAspectRatio = this.allowAnyAspectRatio;
            if (this.company.allowAnyAspectRatio?.updatedAt) {
                const updateAt = dayjs(this.company.allowAnyAspectRatio?.updatedAt).format(
                    "YYYY/MM/DD HH:mm:ss"
                );
                const updateUserName = this.company.allowAnyAspectRatio?.updatedUserName ?? "";

                this.allowAnyAspectRatioInfo = `${updateAt} ${updateUserName} が設定。`;
            }

            this.v2_post = this.company.options?.includes("v2-post");
            this.reviews_disabled = this.company.options?.includes("reviews-disabled");
            this.v2_post_approval_manual =
                this.company.options?.includes("v2-post-approval-manual");
        } catch (e) {
            console.log(e);
            throw Error("企業情報が見つかりませんでした。");
        } finally {
            this.isLoading = false;
        }
    }

    async getAppleBrands(): Promise<void> {
        try {
            if (!this.canShowCompany) {
                // 管理者だけがABCブランド一覧を取得・設定する
                return;
            }
            let poiGroupId = parseInt(this.$route?.params?.poiGroupId as string, 10);
            if (isNaN(poiGroupId)) {
                // 新規登録時
                poiGroupId = 0;
            }
            let after = "";
            let abcCompanyId = "";
            this.appleBrandsMap.clear();
            this.appleBrandsItems.splice(0);
            // Appleのブランド情報を取得（100件を超える場合は全ページを取得する）
            do {
                const nextAppleBrands = await aapi.listAppleBrands(poiGroupId, after);
                nextAppleBrands.data?.data?.forEach((brand) => {
                    if (abcCompanyId !== brand.companyId) {
                        this.appleBrandsItems.push({
                            isHeader: true,
                            title: "ABC会社ID:" + brand.companyId, // ABC会社名はAPIから取得不可能
                        });
                        abcCompanyId = brand.companyId;
                    }
                    this.appleBrandsItems.push({
                        isHeader: false,
                        title: brand.businessDetails?.displayNames[0].name ?? "名称不明のブランド",
                        id: BigInt(brand.id),
                        keyword: brand.companyId,
                    });
                    this.appleBrandsMap.set(BigInt(brand.id), {
                        id: brand.id,
                        name: brand.businessDetails?.displayNames[0].name,
                    });
                });
                after = nextAppleBrands.data.pagination?.cursors?.after ?? null;
            } while (after);
        } catch (e) {
            console.error(e);
            throw Error("Appleからの情報取得に失敗しました。");
        } finally {
            this.appleBrandsSelectLabel = this.canShowCompany
                ? "紐づけるブランド（ABCビジネス）を選択"
                : "";
            this.isLoadingApple = false;
        }
    }

    async submit(): Promise<void> {
        // オーナー提供のトークンを用いる場合「変更提案を自動で元に戻す(1日に1回)」は false で登録させる
        if (this.isNew && this.company.useCustomerGbpToken) {
            this.autoBlock = false;
        }

        // keywordに半角スペースが入っていたら自動で全て置き換えるようにする
        // performance(インサイト)の都合上、半角スペースが入っていると処理できないため
        if (this.company.keywords !== undefined) {
            this.company.keywords = this.company.keywords.map((keyword) => {
                return keyword.replace(/\s/g, "　");
            });
        }

        // バリデーション
        const result = await this.$refs.observer.validate();
        if (!result.valid) {
            this.addSnackbarMessages({
                text: "入力に誤りがあります。内容を確認してください。",
                color: "danger",
                options: { top: false },
            });
            return;
        }

        if (this.beforeAutoBlock !== this.autoBlock && this.autoBlock && !this.isConsented) {
            this.isConsented = false;
            this.confirmAutoBlock = true;
        } else if (
            this.beforeAllowAnyAspectRatio !== this.allowAnyAspectRatio &&
            this.allowAnyAspectRatio &&
            !this.isConsentedAspectRatio
        ) {
            this.isConsentedAspectRatio = false;
            this.confirmAllowAnyAspectRatio = true;
        } else {
            await this.upsert();
        }
    }

    private setOption(company: EntitiesCompany, target: string, value: boolean) {
        if (!("options" in company)) {
            company.options = [];
        }
        if (company.options.includes(target)) {
            // オプションが存在している
            if (!value) {
                company.options = company.options.filter((item) => item !== target);
            }
        } else {
            // オプションは存在していない
            if (value) {
                company.options.push(target);
            }
        }
    }

    async upsert(): Promise<void> {
        try {
            this.message = "";
            this.isLoading = true;

            const param: EntitiesCompany = { ...this.company };
            if (this.isNew) {
                if (param.useCustomerGbpToken) {
                    param.canUseGbpConsole = false;
                    param.customerGbpTokenInfo = { hasCustomerGbpToken: false };
                } else {
                    param.canUseGbpConsole = true;
                }
            } else {
                // 更新のときは送信しない
                delete param.useCustomerGbpToken;
                delete param.canUseGbpConsole;
                delete param.gmbAccount;
            }
            // citation パラメータが存在しない場合のガード処理
            if (!("citation" in param)) {
                param.citation = {};
            }
            if (!("facebook" in param.citation)) {
                param.citation["facebook"] = { enabled: false };
            }
            if (!("instagram" in param.citation)) {
                param.citation["instagram"] = { enabled: false };
            }
            if (!("yahooplace" in param.citation)) {
                param.citation["yahooplace"] = { enabled: false };
            }
            if (!("apple" in param.citation)) {
                param.citation["apple"] = { enabled: false };
            }

            this.setOption(param, "hosting", this.hosting);
            if (!("custom" in param)) {
                param.custom = {};
            }
            param.custom["hostingS3RootPath"] = this.storePageHostingS3Path;
            param.custom["hostingRootURL"] = this.storePageHostingPublicRootPath;
            param.custom["hostingImagePath"] = this.storePageHostingImagePath;
            param.custom["cloudfrontDistributionID"] =
                this.storePageHostingCloudfrontDistributionID;
            param.custom["cloudfrontDistributionPath"] =
                this.storePageHostingCloudfrontDistributionPath;
            param.custom["hostingTrafficLimitPerMonth"] = this.revertUnit(
                this.hostingTrafficLimit,
                this.hostingTrafficLimitUnit
            );
            param.custom["structuredIDEdit"] = this.canEditStructuredID;
            param.hostingReferers = this.referers;

            this.setOption(param, "v2-post", this.v2_post);
            this.setOption(param, "reviews-disabled", this.reviews_disabled);
            this.setOption(param, "v2-post-approval-manual", this.v2_post_approval_manual);
            param.citation["facebook"].enabled = this.facebook;
            param.citation["instagram"].enabled = this.instagram;
            param.citation["yahooplace"].enabled = this.yplace;
            param.citation["apple"].enabled = this.apple;
            param.citation["apple"].brands = this.appleBrands.map((id) => ({
                brandId: this.appleBrandsMap.get(id).id,
                brandName: this.appleBrandsMap.get(id).name,
            }));

            if (!("autoBlockUpdates" in param)) {
                param.autoBlockUpdates = {};
            }
            if (
                (this.isNew && this.autoBlock) || // 企業追加時
                (!this.isNew && this.beforeAutoBlock !== this.autoBlock) // 企業編集時
            ) {
                // 自動で元に戻すの設定値が変更された
                param.autoBlockUpdates.googleBusinessProfile = this.autoBlock;
            } else {
                // 変更がなければ更新しない
                delete param.autoBlockUpdates;
            }
            if (!("allowAnyAspectRatio" in param)) {
                param.allowAnyAspectRatio = {};
            }
            if (
                (this.isNew && this.allowAnyAspectRatio) || // 企業追加時
                (!this.isNew && this.beforeAllowAnyAspectRatio !== this.allowAnyAspectRatio) // 企業編集時
            ) {
                // 縦横比の設定値が変更された
                param.allowAnyAspectRatio.googleBusinessProfile = this.allowAnyAspectRatio;
            } else {
                // 変更がなければ更新しない
                delete param.allowAnyAspectRatio;
            }
            param.defaultRadius = parseInt(this.formRadius, 10);
            if (!param.defaultRadius) {
                param.defaultRadius = this.defaultRadius;
            }
            const actionType = this.isNew ? "post" : "put";
            const response = await requiredAuth<EntitiesCompany>(
                this.isNew ? "post" : "put",
                this.isNew
                    ? `${import.meta.env.VITE_APP_API_BASE}v1/companies`
                    : `${import.meta.env.VITE_APP_API_BASE}v1/companies/${this.company.poiGroupID}`,
                getOperationLogParams(this.$route, actionType),
                param
            );
            if (response == null || response.data == null) {
                // 権限不足
            } else {
                const message = this.isNew ? "企業を登録しました" : "企業を更新しました";
                this.addSnackbarMessages({
                    text: message,
                    color: "success",
                });
                // オーナー権限の時のみcompanyのstateを更新する
                if (!this.canShowCompany && this.canShowCompanyOwner) {
                    await this.setCompany(this.company.poiGroupID);
                    this.beforeAutoBlock = this.autoBlock;
                    this.beforeAllowAnyAspectRatio = this.allowAnyAspectRatio;
                }
            }
            if (this.canShowCompany) {
                this.$router.push({ name: "AdminCompanies" });
            }
        } catch (e) {
            console.error(e);
            // axiosErrorで且つpoiGroupIDの重複エラーだった場合はsentryのメッセージを専用のものに変更する
            if (isAxiosError(e) && e.response?.status === 500 && e.response?.data?.errorMessage) {
                const message = e.response.data.errorMessage;
                if (
                    message.includes("企業新規登録エラー") &&
                    message.includes("The conditional request failed")
                ) {
                    const errorDialogMessage: ErrorDialogMessage = {
                        title: "企業の登録に失敗しました",
                        detail: "お手数ですがシステムソリューションチームまでお問い合わせください",
                        buttonType: "reload",
                    };
                    useDialog().showSentry(errorDialogMessage);
                }
            }
            throw Error(this.isNew ? this.dict.error_register : this.dict.error_update);
        } finally {
            this.isLoading = false;
        }
    }

    toParams(): Partial<EntitiesCompany> {
        return this.company;
    }

    goBack(): void {
        this.$router.push({ name: "AdminCompanies" });
    }

    @Watch("company.keywords")
    onCWchange(val, prev): void {
        this.onKeywordChange(this.companyKeywordSettings, "keywords", val, prev);
    }

    @Watch("company.searchKeywordVolumeKeywords")
    onSKVKchange(val, prev): void {
        this.onKeywordChange(
            this.searchKeywordVolumeKeywordsSettings,
            "searchKeywordVolumeKeywords",
            val,
            prev
        );
    }

    @Watch("company.ipWhitelist")
    onIPchange(val, prev): void {
        this.onKeywordChange(this.allowedIPSettings, "ipWhitelist", val, prev);
    }

    @Watch("company.businessTypeList")
    onBusinessTypechange(val, prev): void {
        this.onKeywordChange(this.businessTypeSettings, "businessTypeList", val, prev);
    }

    @Watch("company.hostingReferers")
    onRefererschange(val, prev): void {
        this.onKeywordChange(this.referersSettings, "hostingReferers", val, prev);
    }

    onKeywordChange(target: ComboBoxSettings, targetKeyword: string, val, prev) {
        if (!this.company[targetKeyword] || val?.length === prev?.length) {
            return;
        }

        this.company[targetKeyword] = val.map((v: string) => {
            target.items.push(v);
            return v;
        });
        target.items = Object.assign([], this.company[targetKeyword]);
    }

    /** キーワードのチップ消したら実際に持ってるデータからも消す */
    removeKeyword(targetKeyword: string, val: string) {
        this.company[targetKeyword] = this.company[targetKeyword].filter((v: string) => v !== val);
    }

    // ホスティングの流入元制限設定のキーワードを削除
    removeKeywordReferers(val: string) {
        this.referers = this.referers.filter((v) => v !== val);
    }

    getUnit(value: number | undefined): { value: number | undefined; unit: string } {
        if (value === undefined) {
            return { value: undefined, unit: "" };
        }
        let thisUnit: number, thisSize: number;
        thisSize = value;
        const unit = 1000;
        for (
            let i = 0, j = 0, sizeTemp = value / unit;
            sizeTemp >= 1 && j < this.unitList.length;
            i++, j++, sizeTemp /= unit
        ) {
            thisUnit = i;
            thisSize = sizeTemp;
        }
        return {
            value: Math.round(thisSize * 10 ** 2) / 10 ** 2,
            unit: this.unitList[thisUnit],
        };
    }

    revertUnit(value: number, unit: string): number {
        const index = this.unitList.findIndex((u) => u === unit);
        return value * 1000 ** (index + 1);
    }

    // 数値を 1000単位で単位変換して、小数点第2位まで＋単位を文字列で返す
    convertUnit(value: number | undefined): string {
        const r = this.getUnit(value);
        if (r.value === undefined) {
            return "無制限";
        }
        return r.value + " " + r.unit;
    }

    async hostingTrafficLimitUnitChanged() {
        const oldIndex = this.unitList.findIndex(
            (unit) => this.hostingTrafficLimitOldUnit === unit
        );
        if (oldIndex > -1) {
            this.hostingTrafficLimit = this.hostingTrafficLimit * 1000 ** oldIndex;
        }
        const newIndex = this.unitList.findIndex((unit) => this.hostingTrafficLimitUnit === unit);
        if (newIndex === -1) {
            return;
        }
        this.hostingTrafficLimit = this.hostingTrafficLimit / 1000 ** newIndex;
        this.hostingTrafficLimitOldUnit = this.hostingTrafficLimitUnit;
    }
}
export default toNative(Form);
