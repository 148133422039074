import { apiBase } from "@/const";
import { requiredAuth } from "@/helpers";
import { getOperationLogParams } from "@/routes/operation-log";
import type { OperationLogParams } from "@/routes/operation-log";
import type {
    EntitiesAppleResponseEntitiesPaginatedResponseOfBusiness,
    EntitiesAppleResponseEntitiesPaginatedResponseOfLocation,
    EntitiesAppleLocation,
    EntitiesStore,
    EntitiesStoresResponse,
    EntitiesAppleLocationUpdateMask,
    ControllersPatchAppleLocationRequest,
    EntitiesAppleCategory,
} from "@/types/ls-api";
import type { RouteLocationNormalized } from "vue-router";
export const api = {
    listAppleBrands,
    listAppleLocations,
    batchGet,
    patchAppleLocation,
    listStores,
    putStoreApple,
    listCategories,
};
async function listAppleBrands(
    poiGroupId: number,
    after: string = "",
    size: number = 100
): Promise<EntitiesAppleResponseEntitiesPaginatedResponseOfBusiness> {
    const url = `${apiBase}/companies/${poiGroupId}/apple/brands`;
    const res = await requiredAuth<EntitiesAppleResponseEntitiesPaginatedResponseOfBusiness>(
        "get",
        url,
        {
            size,
            after,
        }
    );
    return res.data;
}
async function listAppleLocations(
    poiGroupId: number,
    after: string = "",
    size: number = 100
): Promise<EntitiesAppleResponseEntitiesPaginatedResponseOfLocation> {
    const url = `${apiBase}/companies/${poiGroupId}/apple/locations`;
    const res = await requiredAuth<EntitiesAppleResponseEntitiesPaginatedResponseOfLocation>(
        "get",
        url,
        {
            size,
            after,
        }
    );
    return res.data;
}
async function batchGet(
    poiGroupId: number,
    locationIds: string[]
): Promise<EntitiesAppleResponseEntitiesPaginatedResponseOfLocation> {
    const url = `${apiBase}/companies/${poiGroupId}/apple/locations/batchGet`;
    const res = await requiredAuth<EntitiesAppleResponseEntitiesPaginatedResponseOfLocation>(
        "post",
        url,
        null,
        { locationIds }
    );
    return res.data;
}
async function patchAppleLocation(
    poiGroupId: number,
    poiId: number,
    location: EntitiesAppleLocation,
    updateMask: EntitiesAppleLocationUpdateMask[],
    operationLogParams: OperationLogParams
): Promise<EntitiesAppleLocation> {
    const url = `${apiBase}/companies/${poiGroupId}/apple/locations/${poiId}`;
    const request: ControllersPatchAppleLocationRequest = {
        location,
        updateMask,
    };
    const res = await requiredAuth<EntitiesAppleLocation>(
        "patch",
        url,
        operationLogParams,
        request
    );
    return res.data;
}
async function listStores(poiGroupId: number): Promise<EntitiesStore[]> {
    const url = `${apiBase}/companies/${poiGroupId}/stores`;
    const res = await requiredAuth<EntitiesStoresResponse>("get", url, {});
    return res.data.stores ?? [];
}
async function putStoreApple(
    route: RouteLocationNormalized,
    poiGroupId: number,
    poiId: number,
    param?: EntitiesStore["appleBusinessConnect"]
): Promise<EntitiesStore> {
    const url = `${apiBase}/companies/${poiGroupId}/stores/${poiId}/appleBusinessConnect`;
    const res = await requiredAuth<EntitiesStore>(
        "put",
        url,
        getOperationLogParams(route, "page-linkage"),
        { appleBusinessConnect: param }
    );
    return res.data;
}
async function listCategories(poiGroupId: number): Promise<EntitiesAppleCategory[]> {
    const url = `${apiBase}/companies/${poiGroupId}/apple/categories`;
    const res = await requiredAuth<EntitiesAppleCategory[]>("get", url);
    return res.data;
}
