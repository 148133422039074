import { router } from "@/routes";
import Oruga from "@oruga-ui/oruga-next";
import { bulmaConfig } from "@oruga-ui/theme-bulma";
import { createVuetify } from "vuetify";
import * as directives from "vuetify/directives";
import "vuetify/dist/vuetify.min.css";
import { configure, defineRule } from "vee-validate";
import { email, numeric } from "@vee-validate/rules";
import { localize, setLocale } from "@vee-validate/i18n";
import ja from "@vee-validate/i18n/dist/locale/ja.json";
import { createApp, type ComponentPublicInstance } from "vue";
import { createPinia, PiniaVuePlugin } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import App from "./App.vue";
import CGrid from "vue-cheetah-grid";
import VueGtm from "@gtm-support/vue-gtm";
import * as Sentry from "@sentry/vue";
import ToolTipIcon from "@/components/shared/tooltip-icon";
import { currentTheme, getVuetifyThemes } from "@/components/shared/theme";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { config, dom, library } from "@fortawesome/fontawesome-svg-core";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(utc); // dayjs.utc をつかえるようにする
dayjs.extend(customParseFormat);
import * as am4core from "@amcharts/amcharts4/core";
am4core.options.autoDispose = true;
import {
    faArrowAltCircleLeft,
    faBell as faBellRegular,
    faCircleCheck,
    faComment,
    faCommentDots,
    faCopy,
    faEye as faEyeRegular,
    faFileArchive,
    faFileVideo,
    faHandPointer,
    faHeart,
    faImages,
    faPaperPlane,
    faTrashAlt,
} from "@fortawesome/free-regular-svg-icons";
import {
    faAlignJustify,
    faArrowDown,
    faArrowLeft,
    faArrowRight,
    faArrowUp,
    faBan,
    faBell,
    faBook,
    faBriefcase,
    faBuilding,
    faBullhorn,
    faCalendar,
    faCaretDown,
    faChartArea,
    faCheck,
    faCircleArrowDown,
    faCircleArrowUp,
    faCircleDown,
    faCircleInfo,
    faCircleUp,
    faClipboardList,
    faClock,
    faCloudUploadAlt,
    faCog,
    faComments,
    faCrop,
    faDownload,
    faEdit,
    faEllipsisH,
    faEllipsisV,
    faExclamation,
    faExclamationCircle,
    faExclamationTriangle,
    faExternalLinkAlt,
    faEye,
    faFileCsv,
    faFileDownload,
    faFileExcel,
    faFileImage,
    faFileImport,
    faFilter,
    faGear,
    faGlobe,
    faHeartbeat,
    faHourglass,
    faImage,
    faInfo,
    faInfoCircle,
    faLevelUpAlt,
    faListAlt,
    faLongArrowAltDown,
    faLongArrowAltUp,
    faMagnifyingGlassLocation,
    faMapLocationDot,
    faMapMarkerAlt,
    faPen,
    faPencil,
    faPersonRunning,
    faPhone,
    faPhoneAlt,
    faPlugCircleXmark,
    faPlus,
    faPowerOff,
    faQuoteLeft,
    faRectangleList,
    faRedo,
    faRefresh,
    faReply,
    faRobot,
    faRotate,
    faRoute,
    faSearchMinus,
    faSearchPlus,
    faShop,
    faSpinner,
    faSquare,
    faStore,
    faThumbsDown,
    faThumbsUp,
    faUndo,
    faUsers,
    faUserTie,
    faVideo,
    faXmark,
} from "@fortawesome/free-solid-svg-icons"; // fas
import { faGoogle, faFacebookF, faInstagram, faApple } from "@fortawesome/free-brands-svg-icons";
import { useDialog } from "./storepinia/dialog";
import { CustomIcons } from "@/components/icon/customIcons";

config.showMissingIcons = true;
library.add(
    faAlignJustify,
    faApple,
    faArrowAltCircleLeft,
    faArrowDown,
    faArrowLeft,
    faArrowRight,
    faArrowUp,
    faBan,
    faBell,
    faBellRegular,
    faBook,
    faBriefcase,
    faBuilding,
    faBullhorn,
    faCalendar,
    faCaretDown,
    faChartArea,
    faCheck,
    faCircleArrowDown,
    faCircleArrowUp,
    faCircleCheck,
    faCircleDown,
    faCircleInfo,
    faCircleUp,
    faClipboardList,
    faClock,
    faCloudUploadAlt,
    faCog,
    faComment,
    faCommentDots,
    faComments,
    faCopy,
    faCrop,
    faDownload,
    faEdit,
    faEllipsisH,
    faEllipsisV,
    faExclamation,
    faExclamationCircle,
    faExclamationTriangle,
    faExternalLinkAlt,
    faEye,
    faEyeRegular,
    faFacebookF,
    faFileArchive,
    faFileCsv,
    faFileDownload,
    faFileExcel,
    faFileImage,
    faFileImport,
    faFileVideo,
    faFilter,
    faGear,
    faGlobe,
    faGoogle,
    faHandPointer,
    faHeart,
    faHeartbeat,
    faHourglass,
    faImage,
    faImages,
    faInfo,
    faInfoCircle,
    faInstagram,
    faLevelUpAlt,
    faListAlt,
    faLongArrowAltDown,
    faLongArrowAltUp,
    faMagnifyingGlassLocation,
    faMapLocationDot,
    faMapMarkerAlt,
    faPaperPlane,
    faPen,
    faPencil,
    faPersonRunning,
    faPhone,
    faPhoneAlt,
    faPlugCircleXmark,
    faPlus,
    faPowerOff,
    faQuoteLeft,
    faRectangleList,
    faRedo,
    faRefresh,
    faReply,
    faRobot,
    faRotate,
    faRoute,
    faSearchMinus,
    faSearchPlus,
    faShop,
    faSpinner,
    faSquare,
    faStore,
    faThumbsDown,
    faThumbsUp,
    faTrashAlt,
    faUndo,
    faUsers,
    faUserTie,
    faVideo,
    faXmark
);
const app = createApp(App);
app.component("FontAwesomeIcon", FontAwesomeIcon);
dom.watch();

// Piniaの設定
app.use(PiniaVuePlugin);
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
app.use(pinia);

// VueRouterの設定(routerでpiniaを用いているので、Piniaの設定後に行う必要がある)
app.use(router);
app.use(Oruga, bulmaConfig);

// VeeValidateの設定
import { Form, Field } from "vee-validate";
import { defineVeeValidateRules } from "./helpers";
app.component("VeeForm", Form);
app.component("Field", Field);
configure({ generateMessage: localize({ ja }) });
setLocale("ja");
defineRule("email", email);
defineRule("numeric", numeric);
defineVeeValidateRules();

const vuetify = createVuetify({
    directives,
    icons: {
        aliases: {
            google: "fab fa-google",
            yahoo: "custom:YahooLogo",
            facebook: "fab fa-facebook-f",
            instagram: "fab fa-instagram",
            apple: "fab fa-apple",
        },
        sets: {
            custom: CustomIcons,
        },
    },
    theme: {
        // ドメインに応じてテーマを切り替え
        defaultTheme: currentTheme().themeName,
        themes: getVuetifyThemes(),
    },
});
app.use(vuetify);

app.use(CGrid);

app.use(VueGtm, {
    id: `${import.meta.env.VITE_APP_GTM_ID}`,
    enabled: true,
});

app.config.warnHandler = function (
    msg: string,
    instance: ComponentPublicInstance | null,
    trace: string
) {
    // `trace` is the component hierarchy trace
    console.error(`[Vue warn]: ${msg}\n${trace}`);
};

Sentry.init({
    app,
    dsn: "https://bd10f83ef1dd4e0f84f7877f0cf34ad3@o305153.ingest.sentry.io/1760858",
    environment: import.meta.env.VITE_APP_APP_ENV,
    logErrors: true,
    beforeSend(event, hint) {
        // 2023-10-18 次のメッセージの場合は無視する
        // https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver#observation_errors
        // リサイズハンドラの扱いが間違っている可能性が高いがうまく解消できないので、一旦無視するようにする
        if (event.message === "ResizeObserver loop completed with undelivered notifications.") {
            console.error(event.message);
            return null;
        }
        console.error("[Sentry Error] Event", event);
        console.error("[Sentry Error] Hint", hint);
        console.error(hint.originalException);
        if (event.exception) {
            try {
                useDialog().showSentry();
            } catch (e) {
                // もし出せなければSentryのダイアログを表示
                Sentry.showReportDialog({
                    eventId: event.event_id,
                    title: "問題が発生したため、表示ができません。ご不便をおかけして申し訳ありません。",
                    subtitle:
                        "この問題は、サービス担当に通知済みです。更に詳細なエラー報告がある場合、以下に説明を記載して送信ください。",
                    subtitle2: "",
                });
            }
        }
        // デバッグ実行中は送信しない
        return import.meta.env.NODE_ENV === "development" ? null : event;
    },
});

// 基底コンポーネントのグローバル登録
app.component("ToolTipIcon", ToolTipIcon);

app.mount("#app");
