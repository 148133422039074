import type {
    EntitiesV2PostRequest,
    EntitiesV2YahooPostData,
    EntitiesV2FacebookPostData,
    EntitiesV2InstagramPostData,
    EntitiesV2PostFile,
    EntitiesV2PostTargets,
    EntitiesV2PostData,
    EntitiesV2PoiState,
} from "@/types/ls-api";
import type { PostGMBData } from "./gmb/gmb-request";

export class PostData implements Partial<EntitiesV2PostRequest> {
    incompleteNew: boolean = false;
    incompleteEdit: boolean = false;
    Facebook?: EntitiesV2FacebookPostData;
    Yahoo?: EntitiesV2YahooPostData;
    GMB?: PostGMBData;
    Instagram?: EntitiesV2InstagramPostData;
    createDateTime?: string;
    edit?: boolean;
    status?: EntitiesV2PoiState;
    fileName?: string;
    fileNames?: string[];
    files?: EntitiesV2PostFile[];
    lastUpdateUserName?: string;
    poiGroupID?: number;
    posting?: string;
    cancellationReservation?: string;
    previousPost?: EntitiesV2PostData;
    targets = new PostTargets();
    targetsFile: string;
    text?: string;
    title: string;
    userName?: string;
    aspectNoCheck?: boolean;
    judgementWantUserNames?: string[] = [];
    judgementRequestRemark?: string = "";
    judgementRequestIsDelete?: boolean;
}

export class PostTargets implements Partial<EntitiesV2PostTargets> {
    all?: boolean;
    poiIDs?: number[];
    areas?: number[];
}
